export default {
  name: "LandingPage",
  created() {
    this.$nextTick(function () {
      this.getRiclist();
    });
  },
  mounted() {
    this.$nextTick(function () {
      $(".form-control").click(function () {
        $(".form-control").datepicker("show");
      });
      //  //改变meta的keywords
      //  $('meta[name="keywords"]').attr('content', "warrant calculator, warrant terms, RHB, malaysia warrants,"+localStorage.getItem("metaKeywords"));
    });
  },
  data() {
    return {
      ricNmae: null,
      exchangeratioTurn: null
    };
  },
  methods: {
    // 初始化
    _info: function (data) {
      let then = this;
      $(".info").click(function () {
        then.GetMarketData(then.ricNmae);
        // $(".ui_widget").find('input').val(data.underlying_name + ' (' + data.dsply_nmll + ')');
      });
    },
    // 接收Marke数据
    GetMarketData: function (ric) {
      let then = this;
      $.ajax({
        //请求方式
        type: "POST",
        //发送请求的地址
        url: this.$js.webservice + "GetMarketData",
        //服务器返回的数据类型
        dataType: "json",
        // async: false,
        //发送到服务器的数据，对象必须为key/value的格式，jquery会自动转换为字符串格式
        data: { token: "webkey", type: "warrantdata", ric: ric },
        success: function (data) {
          //请求成功函数内容
          if (data.status === "SUCCESS") {
            then.assignmentMarke(data[ric], data.exchangeratio);
            then.assignmentPriceMatrix(data[ric], data.exchangeratio);
            // then.currencySwitch(data.exchangeratio, data[ric])
          }
        },
        error: function (jqXHR) {
          //请求失败函数内容
        }
      });
    },
    // Marke赋值
    assignmentMarke: function (data, exchangeratio) {
      let then = this;
      var $information = $(".information");
      var $simulator = $(".simulator");
      $(".calculator_call_name")
        .find("span")
        .text(data.type);
      // Warrant information
      var effective_gearing = data.effective_gearing;
      if (effective_gearing !== "N/A") {
        effective_gearing =
          this.$js.formatNumber(effective_gearing, 1, 0) + " x";
      }
      var sensitivity = data.sensitivity;
      if (sensitivity !== "N/A") {
        sensitivity = this.$js.formatNumber(sensitivity, 1, 0) + " ticks";
      }
      $information
        .find(".underlying_price")
        .text(
          this.$js.curreryRetrun(data.underlying_curr) +
          " " +
          this.$js.formatNumber(data.underlying_price, 3, 0)
        );
      $information
        .find(".exercise_price")
        .text(
          this.$js.curreryRetrun(data.underlying_curr) +
          " " +
          this.$js.formatNumber(data.exercise_price, 4, 0)
        );
      $information
        .find(".conv_ratio")
        .text(this.$js.formatNumber(data.conv_ratio, 4, 0));
      $information.find(".maturity").text(data.maturity);
      $information.find(".effective_gearing").text(effective_gearing);
      $information.find(".sensitivity").text(sensitivity);

      // warrant Simulator
      // Underlying Target Price
      var sPrice = Number(data.underlying_price);
      var sEPrice = Number(data.exercise_price);
      var sDays;
      var real_volatility;
      if (data.v_implied_volatility) {
        real_volatility = Number(data.v_implied_volatility);
      } else {
        real_volatility = 0;
      }
      $simulator
        .find(".underlying_price")
        .val(
          this.$js.curreryRetrun(data.underlying_curr) +
          " " +
          this.$js.formatNumber(data.underlying_price, 3, 0)
        );
      $simulator.find(".underlying_price").on("input", function () {
        if ($(this).val().length === 0) {
          sPrice = 0;
        } else {
          sPrice = Number($(this).val());
        }
        var per =
          ((sPrice - Number(data.underlying_price)) /
            Number(data.underlying_price)) *
          100;
        $simulator.find(".percentage").val(then.$js.formatNumber(per, 1, 0));
        $simulator
          .find(".percentage")
          .width($simulator.find(".percentage").val().length * 10);
        then.assignmentResult(
          sPrice,
          sEPrice,
          sDays,
          real_volatility,
          data,
          exchangeratio
        );
      });
      // Expect to sell on
      var todayDate = new Date(new Date().toDateString());
      var saveMaturity = data.maturity;
      var endDate = new Date(saveMaturity);
      var selectdate;
      // 日历选择器
      $(".form-control").datepicker("destroy");
      $(".form-control")
        .datepicker({
          autoclose: true,
          dateFormat: "dd M y",
          minDate: todayDate,
          maxDate: endDate,
          onSelect: function (dateText, inst) {
            selectdate = new Date(dateText);
            $(".form-control").datepicker("setDate", selectdate);
            $simulator
              .find(".days")
              .val(parseInt((endDate - selectdate) / (24 * 3600 * 1000)));
            $simulator
              .find(".days")
              .width($simulator.find(".days").val().length * 10);
            sDays = parseInt((endDate - selectdate) / (24 * 3600 * 1000)) / 365;
            then.assignmentResult(
              sPrice,
              sEPrice,
              sDays,
              real_volatility,
              data,
              exchangeratio
            );
          }
        })
        .datepicker("setDate", todayDate);
      $("#ui-datepicker-div").css({ "overflow-y": "hidden" });
      // Default
      $simulator.find(".days").val(this.intervalDate(todayDate, endDate));
      sDays = Number($simulator.find(".days").val()) / 365;
      $simulator
        .find(".days")
        .width($simulator.find(".days").val().length * 10);
      $simulator
        .find(".percentage")
        .val(
          this.$js.formatNumber(
            ((Number(data.underlying_price) - Number(data.underlying_price)) /
              Number(data.underlying_price)) *
            1,
            1,
            0
          )
        );
      $simulator
        .find(".percentage")
        .width($simulator.find(".percentage").val().length * 10);
      $simulator.find(".percentage").on("input", function () {
        var pNum = $(this).val();
        if ($(this).val().length !== 0) {
          $(this).width($(this).val().length * 10);
        } else {
          $(this).width(10);
          pNum = 0;
        }
        var price = Number(data.underlying_price) * (1 + Number(pNum) * 0.01);
        $simulator
          .find(".underlying_price")
          .val(
            then.$js.curreryRetrun(data.underlying_curr) +
            " " +
            then.$js.formatNumber(price, 3, 0)
          );
        sPrice = price;
        then.assignmentResult(
          sPrice,
          sEPrice,
          sDays,
          real_volatility,
          data,
          exchangeratio
        );
      });
      if (Number($simulator.find(".days").val()) >= 2) {
        $simulator.find(".i_day").text("days");
      } else {
        $simulator.find(".i_day").text("day");
      }
      $simulator.find(".days").on("input", function () {
        var addDays = parseInt($(this).val());
        if ($(this).val().length !== 0) {
          $(this).width($(this).val().length * 10);
        } else {
          $(this).width(10);
          addDays = 0;
        }
        if (Number($(this).val()) >= 2) {
          $simulator.find(".i_day").text("days");
        } else {
          $simulator.find(".i_day").text("day");
        }
        var tempenddate = new Date(saveMaturity);
        var newDate = new Date(saveMaturity);

        newDate.setDate(tempenddate.getDate() - addDays);
        $(".form-control").datepicker("setDate", newDate);
        sDays = addDays / 365;
        then.assignmentResult(
          sPrice,
          sEPrice,
          sDays,
          real_volatility,
          data,
          exchangeratio
        );
      });
      then.assignmentResult(
        sPrice,
        sEPrice,
        sDays,
        real_volatility,
        data,
        exchangeratio
      );
    },
    // Result赋值
    assignmentResult: function (
      sPrice,
      sEPrice,
      sDays,
      real_volatility,
      data,
      exchangeratio
    ) {
      var $result = $(".result");
      var BID_NETCHNG = data.BID_NETCHNG;
      if (BID_NETCHNG === "") {
        BID_NETCHNG = "N/A";
      } else if (BID_NETCHNG > 0) {
        $result.find(".BID_NETCHNG").addClass("add");
        $result.find(".BID_NETCHNG").removeClass("reduce");
      } else if (BID_NETCHNG < 0) {
        $result.find(".BID_NETCHNG").addClass("reduce");
        $result.find(".BID_NETCHNG").removeClass("add");
      } else if (Number(BID_NETCHNG) == 0) {
        $result.find(".BID_NETCHNG").removeClass("add");
        $result.find(".BID_NETCHNG").removeClass("reduce");
      }
      var BID_PCTCHNG = this.$js.formatNumber(data.BID_PCTCHNG, 1, 0);
      if (BID_PCTCHNG === "") {
        BID_PCTCHNG = "N/A";
      } else if (BID_PCTCHNG > 0) {
        $result.find(".BID_PCTCHNG").addClass("add");
        $result.find(".BID_PCTCHNG").removeClass("reduce");
        BID_PCTCHNG = BID_PCTCHNG + "%";
      } else if (BID_PCTCHNG < 0) {
        $result.find(".BID_PCTCHNG").addClass("reduce");
        $result.find(".BID_PCTCHNG").removeClass("add");
        BID_PCTCHNG = BID_PCTCHNG + "%";
      } else if (Number(BID_PCTCHNG) == 0) {
        $result.find(".BID_PCTCHNG").removeClass("add");
        $result.find(".BID_PCTCHNG").removeClass("reduce");
        BID_PCTCHNG = BID_PCTCHNG + "%";
      }
      // 货币间汇率的转换
      for (var e = 0, emax = exchangeratio.length; e < emax; e++) {
        if (data.underlying_curr === exchangeratio[e].currency) {
          sPrice = parseFloat(sPrice) / parseFloat(exchangeratio[e].rate);
          sEPrice = parseFloat(sEPrice) / parseFloat(exchangeratio[e].rate);
        }
      }
      var CalBid = this.$js.blackScholes(
        sPrice,
        sEPrice,
        sDays,
        real_volatility / 100,
        0.03,
        data.type
      );
      CalBid = CalBid / Number(data.conv_ratio);
      let calNum =this.$js.formatNumber(CalBid, 3, 0).split("")
      let callen = calNum[calNum.length-1]
      if (callen >= 0 && callen <= 2) {
        calNum[calNum.length-1] = 0
        calNum = calNum.join("")
      }
      if (callen >= 3 && callen <= 7) {
        calNum[calNum.length-1] = 5
        calNum = calNum.join("")
      }
      if (callen >= 8 && callen <= 9) {
        calNum[calNum.length-1] = 0
        calNum[calNum.length-2] =  Number(calNum[calNum.length-2]) + 1
        calNum= calNum.join("")
      }
      var CalNetchng = this.$js.formatNumber(
        Number(calNum) - Number(data.BID),
        3,
        0
      );
      if (CalNetchng > 0) {
        $result.find(".CalNetchng").addClass("add");
        $result.find(".CalNetchng").removeClass("reduce");
        CalNetchng = "+" + CalNetchng;
      } else if (CalNetchng < 0) {
        $result.find(".CalNetchng").addClass("reduce");
        $result.find(".CalNetchng").removeClass("add");
      } else if (CalNetchng == 0) {
        $result.find(".CalNetchng").removeClass("add");
        $result.find(".CalNetchng").removeClass("reduce");
      }
      var CalPctchng = this.$js.formatNumber(
        (Number(CalNetchng) / Number(data.BID)) * 100,
        1,
        0
      );
      if (Number(data.BID) !== 0) {
        if (CalPctchng > 0) {
          $result.find(".CalPctchng").addClass("add");
          $result.find(".CalPctchng").removeClass("reduce");
          CalPctchng = "+" + CalPctchng + "%";
        } else if (CalPctchng < 0) {
          $result.find(".CalPctchng").addClass("reduce");
          $result.find(".CalPctchng").removeClass("add");
          CalPctchng = CalPctchng + "%";
        } else if (CalPctchng == 0) {
          $result.find(".CalPctchng").removeClass("add");
          $result.find(".CalPctchng").removeClass("reduce");
          CalPctchng = CalPctchng + "%";
        }
      } else {
        CalPctchng = "N/A";
      }

      $result
        .find(".BID")
        .text(
          this.$js.curreryRetrun(data.currency) +
          " " +
          this.$js.formatNumber(data.BID, 3, 0)
        );
      $result.find(".BID_NETCHNG").text(BID_NETCHNG);
      $result.find(".BID_PCTCHNG").text(BID_PCTCHNG);

      $result
        .find(".CalBid")
        .text(
          this.$js.curreryRetrun(data.currency) +
          " " +
          calNum
        );

      $result.find(".CalNetchng").text(CalNetchng);
      $result.find(".CalPctchng").text(CalPctchng, 3, 0);
    },
    // PriceMatrix赋值
    assignmentPriceMatrix: function (data, exchangeratio) {
      let then = this;
      var $valuation = $(".valuation");
      var $simulated = $(".simulated ");
      var $settlement_tow = $(".settlement_tow");
      var $solution = $(".solution");
      var close = [];
      var averageClosing = "";
      $simulated
        .find(".exercise_price")
        .text(
          this.$js.curreryRetrun(data.underlying_curr) +
          " " +
          this.$js.formatNumberNo(data.exercise_price, 4)
        );
      $simulated
        .find(".conv_ratio")
        .text(this.$js.formatNumberNo(data.conv_ratio, 4));
      $.each(data, function (index, value) {
        if (
          index.indexOf(index.replace(/underlying_close/gi, "")) > 0 &&
          index.indexOf(index.replace(/[^0-9]/gi, "")) > 0
        ) {
          close[index.replace(/[^0-9]/gi, "") - 1] = parseFloat(value);
          for (var i = 0; i < 5; i++) {
            if (i === index.replace(/[^0-9]/gi, "") - 1) {
              $valuation
                .find("." + index)
                .text(
                  data.underlying_curr +
                  " " +
                  then.$js.formatNumber(value, 3, 0)
                );
            }
          }
        }
      });
      averageClosing = this.sumAvg(close);
      $simulated
        .find(".average_closing")
        .text(
          data.underlying_curr +
          " " +
          this.$js.formatNumber(averageClosing, 3, 0)
        );
      $valuation
        .find(".average_closing")
        .text(
          data.underlying_curr +
          " " +
          this.$js.formatNumber(averageClosing, 3, 0)
        );
      var payoutPat;
      if (data.type == "PUT") {
        $(".simulated")
          .find(".exercise_price")
          .prev()
          .html("Exercise Price");
        $(".simulated")
          .find(".average_closing")
          .prev()
          .html("- 5-day Average Closing");
        $(".simulated")
          .find(".exercise_price")
          .parent()
          .insertAfter($(".settlement").find(".simulated>li:first-child"));
        payoutPat =
          (Number(data.exercise_price) - Number(averageClosing)) /
          Number(data.conv_ratio);
      } else {
        $(".simulated")
          .find(".exercise_price")
          .prev()
          .html("- Exercise Price");
        $(".simulated")
          .find(".average_closing")
          .prev()
          .html("5-day Average Closing");
        $(".simulated")
          .find(".average_closing")
          .parent()
          .insertAfter($(".settlement").find(".simulated>li:first-child"));
        payoutPat =
          (Number(averageClosing) - Number(data.exercise_price)) /
          Number(data.conv_ratio);
      }
      // 货币间汇率的转换
      for (var e = 0, emax = exchangeratio.length; e < emax; e++) {
        if (data.underlying_curr === exchangeratio[e].currency) {
          payoutPat = parseFloat(payoutPat) / parseFloat(exchangeratio[e].rate);
        }
      }
      if (Number(payoutPat) < 0) {
        payoutPat = 0;
      }
      $settlement_tow
        .find(".payoutPat")
        .text("RM " + this.$js.formatNumber(payoutPat, 3, 0));
      $solution
        .find(".total_payout")
        .text("RM " + Number(this.$js.formatNumber(payoutPat, 3, 0)) * 0);
      $settlement_tow
        .find("input")
        .val(0)
        .on("input", function () {
          var tNum = $(this).val();
          if ($(this).val().length === 0) {
            tNum = 0;
          }
          $solution
            .find(".total_payout")
            .text(
              "RM " +
              then.$js.formatNumber(
                Number(then.$js.formatNumber(payoutPat, 3, 0)) * Number(tNum),
                3,
                0
              )
            );
        });
    },
    //求平均数
    sumAvg: function (arr) {
      var len = arr.length;
      if (len == 0) {
        return 0;
      } else if (len == 1) {
        return arr[0];
      } else {
        return eval(arr.join("+")) / arr.length;
      }
    },
    // 时间转换
    switchDate: function (date) {
      var dataT, dd, mm, yy;
      var en_mon_arr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      dd = date.getDate();
      mm = en_mon_arr[date.getMonth()];
      yy = date.getFullYear() + "";
      dataT = dd + " " + mm + " " + yy.substring(4 - 2, 4);
      return dataT;
    },
    // 间时计算
    intervalDate: function (start, end) {
      var interval = (end - start) / (1 * 24 * 60 * 60 * 1000);
      return parseInt(interval);
    },
    getRiclist: function () {
      /*获取ric list*/
      let then = this;
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetRICList",
        data: { token: "webkey" },
        dataType: "json",
        success: function (data) {
          if (data.status === "SUCCESS") {
            then.seekRicData(data.ric);
          }
        },
        error: function (error) {
          console.log(error);
        }
      });
    },
    seekRicData: function (data) {
      let then = this;
      this.$js.jqcombobox("#details_combobox");
      $("#details_combobox").combobox({
        select: function (event, ui) {
          then.ricNmae = this.value;
          then.GetMarketData(this.value);
          // console.log(ui.item.innerText.split(" ")[1].split("(")[1].split(")")[0]);
          $('meta[name="keywords"]').attr(
            "content",
            "warrant calculator, warrant terms, RHB, malaysia warrants," +
            ui.item.innerText.split(" ")[0] +
            "," +
            ui.item.innerText
              .split(" ")[1]
              .split("(")[1]
              .split(")")[0]
          );
          $("#details_combobox")
            .parent()
            .find("input")
            .blur();
        }
      });
      this.$js.clickEliminate(".ui_widget");
      var ricTxt = "";
      for (var r = 0, max = data.length; r < max; r++) {
        ricTxt +=
          '<option value="' +
          data[r].ric +
          '">' +
          data[r].dsply_nmll +
          " (" +
          data[r].ticker +
          ")" +
          "</option > ";
      }
      $("#details_combobox").append(ricTxt);
      this.ricNmae = data[0].ric;
      this.GetMarketData(data[0].ric);
      $(".ui_widget")
        .find("input")
        .val(data[0].dsply_nmll + " (" + data[0].ticker + ")");
      this._info(data[0]);
      $('meta[name="keywords"]').attr(
        "content",
        "warrant calculator, warrant terms, RHB, malaysia warrants," +
        data[0].dsply_nmll +
        "," +
        data[0].ticker
      );
    }
  }
};
